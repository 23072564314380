import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, inject, Output } from '@angular/core';
import { RegistrationFormComponent } from '@components/user-registration/registration-form/registration-form.component';
import { ApiConfig } from '@config/api.config';
import { UserData } from '@models/dto/UserData';
import { AuthProvider } from '@providers/IAuthProvider';
import { UserService } from '@services/user.service';

@Component({
  imports: [RegistrationFormComponent],
  selector: 'gk-new-user',
  styleUrls: ['./new-user.component.scss'],
  templateUrl: './new-user.component.html',
})
export class NewUserComponent {
  private authProvider = inject(AuthProvider);
  private http = inject(HttpClient);
  private userService = inject(UserService);

  @Output() navigateAway = new EventEmitter<boolean>(false);

  errorText = '';

  abortRegistration() {
    this.authProvider.signOut();
    this.navigateAway.emit(true);
  }

  submitRegistration(newUser: UserData) {
    this.http
      .post<UserData>(ApiConfig.proxiedBackendUrl + 'user/', {
        email: newUser.email,
        name: newUser.name,
      })
      .subscribe(
        success => {
          this.userService.setUserData(success);
          this.authProvider.renewToken();
          this.navigateAway.emit(true);
        },
        err => {
          if (err.status === 403) {
            this.errorText = 'Kunne ikke lage bruker. Brukeren eksisterer allerede.';
          } else {
            this.errorText = 'Kunne ikke lage bruker. Vennligst prøv igjen.';
          }
        },
      );
  }
}
