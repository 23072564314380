<section>
  <div class="form-group">
    <h3>Registrer ny bruker</h3>
    <p>
      Vi ser at det er første gang du logger inn i Gårdskart via ID-porten. Ved å opprette bruker har du mulighet til å
      lagre dine tegninger så de ligger tilgjengelig neste gang du logger inn.
    </p>
  </div>
  <div class="form-group">
    <gk-registration-form (registerUser)="submitRegistration($event)" />
    @if (errorText?.length) {
      <div class="alert alert-danger">
        {{ errorText }}
      </div>
    }
    <div class="text-center"><button (click)="abortRegistration()" class="btn btn-link">Avbryt</button></div>
  </div>
</section>
