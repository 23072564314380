import { AfterViewInit, Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { NavbarComponent } from '@components/navbar/navbar.component';
import { NewUserComponent } from '@components/user-registration/new-user/new-user.component';
import { AppConfig } from '@config/app.config';

@Component({
  imports: [NavbarComponent, NewUserComponent],
  selector: 'gk-registration-page',
  styleUrls: ['./registration-page.component.scss'],
  templateUrl: './registration-page.component.html',
})
export class UserRegistrationPageComponent implements AfterViewInit {
  #returnUrl = '/search';
  #router = inject(Router);

  navigateAway(navigate: boolean) {
    if (!navigate) {
      return;
    }
    void this.#router.navigateByUrl(this.#returnUrl);
  }

  ngAfterViewInit(): void {
    const storedReturnUrl = localStorage.getItem(AppConfig.KEY_SIGNIN_RETURNURL);
    if (storedReturnUrl?.length > 0) {
      this.#returnUrl = storedReturnUrl;
      localStorage.removeItem(AppConfig.KEY_SIGNIN_RETURNURL);
    }
  }
}
